import killeenpic1 from '../../../assets/images/killeenpic1.jpg';
import killeenpic2 from '../../../assets/images/killeenpic2.jpg';
import killeenpic6 from '../../../assets/images/killeenpic6.jpeg';
import killeenpic7 from '../../../assets/images/killeenpic7.jpg';


//mobile
import killeenpic1Mobile from '../../../assets/images/600x900/killeenpic1Mobile.jpg';
import killeenpic2Mobile from '../../../assets/images/600x900/killeenpic2Mobile.jpg';
import killeenpic5Mobile from '../../../assets/images/600x900/killeenpic5Mobile.jpeg';
import killeenpic6Mobile from '../../../assets/images/600x900/killeenpic6Mobile.jpeg';

export const HeroCarouselObj = [
    {
        id: 1,
        src: killeenpic1,
        mobileSrc: killeenpic1Mobile,
        title: 'Shock The World',
        caption: "Welcome to the Joe Killeen Memorial Foundation",
    },
    {
        id: 2,
        src: killeenpic2,
        mobileSrc: killeenpic2Mobile,
        title: 'About',
        caption: "Joe Killeen and His Foundation",
    },
    {
        id: 3,
        src: killeenpic6,
        mobileSrc: killeenpic5Mobile,
        title: 'Impact',
        caption: "Our Goals and What We've Acheived",
    },
    {
        id: 4,
        src: killeenpic7,
        mobileSrc: killeenpic6Mobile,
        title: 'Events',
        caption: "Join Our Community",
    }
];

